// Generated by Framer (ed8225c)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-hxVbW"

const variantClassNames = {PefQY7b21: "framer-v-2hlsmw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "PefQY7b21", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-2hlsmw", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PefQY7b21"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-b3ff2230-c5c0-41a8-b629-d659f479b689, rgb(0, 0, 0))", ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hxVbW.framer-172hvhd, .framer-hxVbW .framer-172hvhd { display: block; }", ".framer-hxVbW.framer-2hlsmw { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 64px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hxVbW.framer-2hlsmw { gap: 0px; } .framer-hxVbW.framer-2hlsmw > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-hxVbW.framer-2hlsmw > :first-child { margin-left: 0px; } .framer-hxVbW.framer-2hlsmw > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerdVsx2cE2c: React.ComponentType<Props> = withCSS(Component, css, "framer-hxVbW") as typeof Component;
export default FramerdVsx2cE2c;

FramerdVsx2cE2c.displayName = "Navigation";

FramerdVsx2cE2c.defaultProps = {height: 64, width: 1200};

addFonts(FramerdVsx2cE2c, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})